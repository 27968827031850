import React from "react";
import hostMap from "../hostMap";
import WujieReact from "wujie-react";
import { useNavigate } from "react-router-dom";

export default function Chatgpt() {
  const navigate = useNavigate();
  const chatgptUrl = hostMap("//localhost:1002/");
  const props = {
    jump: (name) => {
      navigate(`/${name}`);
    },
  };
  return (
    <iframe
      width="100%"
      style={{height: 'calc(100vh - 72px)'}}
      name="chatgpt"
      src={chatgptUrl}
    ></iframe>
  );
  // return (
  //   <WujieReact
  //     width="100%"
  //     height="calc(100vh - 72px)"
  //     name="chatgpt"
  //     url={chatgptUrl}
  //     sync={true}
  //     props={props}
  //   ></WujieReact>
  // );
}
