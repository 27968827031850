import { BrowserRouter as Router, Route, Routes, NavLink, Navigate, useLocation, useNavigate,  } from "react-router-dom";
import WujieReact from "wujie-react";
import React, { useState, useEffect } from "react";
// import Home from "./pages/Home";
// import React16 from "./pages/React16";
// import React17 from "./pages/React17";
// import Vue2 from "./pages/Vue2";
// import Vue3 from "./pages/Vue3";
// import Vite from "./pages/Vite";
// import Angular12 from "./pages/Angular12";
// import All from "./pages/All";
import ChuHai from "./pages/ChuHai";
import Chatgpt from "./pages/Chatgpt";
import Button from "antd/es/button";
import { UnorderedListOutlined, CaretUpOutlined } from "@ant-design/icons";
import imgUrl from "./assets/images/logo.png"
const { bus } = WujieReact;

const subMap = {
  react16: ["home", "dialog", "location", "communication", "nest", "font"],
  react17: ["home", "dialog", "location", "communication", "state"],
  vue2: ["home", "dialog", "location", "communication"],
  vue3: ["home", "dialog", "location", "contact", "state"],
  vite: ["home", "dialog", "location", "contact"],
};

function Nav() {
  const location = useLocation();
  const navigation = useNavigate(); 
  const [chatgpt, setChatgpt] = useState(location.pathname.includes("chatgpt"));

  const degrade = window.Proxy
  useEffect(() => {
    function handleMessage(event) {
      if (event.data === 'chatgpt') {
        navigation('/chatgpt');
      }
    }

    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);
  useEffect(() => {
    if(location.pathname === '/chatgpt'){
      document.body.style.overflowY = 'hidden';
    }else {
      document.body.style.overflowY = 'auto';
    }
  },[location.pathname])
  // 在 xxx-sub 路由下子应用将激活路由同步给主应用，主应用跳转对应路由高亮菜单栏
  bus.$on("sub-route-change", (name, path) => {
    const mainName = `${name}-sub`;
    const mainPath = `/${name}-sub${path}`;
    const currentPath = window.location.hash.replace("#", "")
    if(currentPath.includes(mainName) && currentPath !== mainPath) {
      navigation(mainPath);
    }
  });


  const handleFlag = (name) => {
    switch (name) {
      case "chatgpt":
        setChatgpt(!chatgpt);
        break;
      default:
        break;
    }
  };
  // const imgUrl = "./assets/images/logo.png"
  return (
    <nav className="nav-cont">
      <div>
        <img src={imgUrl} alt="出海营销导航" className="logo" />
      </div>
      
      <NavLink to="/"  className={({ isActive }) => (isActive ? "active" : "inactive")}>
        首页
      </NavLink>
      <NavLink to="/chatgpt" className={({ isActive }) => (isActive ? "active" : "inactive")}>
        ChatGPT
      </NavLink>
    
      <Button type="primary" className="menu-icon" icon={<UnorderedListOutlined />}></Button>
    </nav>
  );
}

function App() {
  const [active, setActive] = useState(false);
  

  function changeActive(val) {
    setActive(val);
  }

  return (
    <div className="app">
      <Router>
        <div className={active ? "nav active" : "nav"}>
          <Nav />
        </div>

        <div className="content" onClick={() => changeActive(false)}>
          <Routes>
            <Route exact path="/" element={<ChuHai />} />
            <Route path="/chatgpt" element={<Chatgpt />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </div>
      </Router>
    </div>
  );
}

export default App;
