import React from "react";
import hostMap from "../hostMap";
import WujieReact from "wujie-react";
import { useNavigate } from "react-router-dom";

export default function ChuHai() {
  const navigation = useNavigate();
  const chuHaiUrl = hostMap("//localhost:8080/");
  console.log(chuHaiUrl, 'chuHaiUrl');
  const props = {
    jump: (name) => {
      console.log(name, "name");
      navigation(`/${name}`);
    },
  };
  return (
    <iframe
      width="100%"
      style={{height: 'calc(100vh - 72px)'}}
      name="chuhai"
      src={chuHaiUrl}
      
    ></iframe>
  );
  // return (
  //   <WujieReact
  //     width="100%"
  //     height="100%"
  //     name="chuhai"
  //     url={chuHaiUrl}
  //     sync={true}
  //     props={props}
  //   ></WujieReact>
  // );
}
