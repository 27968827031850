function isTest() {
  const regex = /^http:\/\/192\.168\.129\.164\//;
  const url = window.location.href;
  if (regex.test(url)) {
    return true;
  } else {
    return false;
  }
}
const map = {
  // "//localhost:7100/": "//wujie-micro.github.io/demo-react17/",
  // "//localhost:7200/": "//wujie-micro.github.io/demo-vue2/",
  // "//localhost:7300/": "//wujie-micro.github.io/demo-vue3/",
  // "//localhost:7400/": "//wujie-micro.github.io/demo-angular12/",
  // "//localhost:7500/": "//wujie-micro.github.io/demo-vite/",
  // "//localhost:7600/": "//wujie-micro.github.io/demo-react16/",
  // "//localhost:7700/": "//wujie-micro.github.io/demo-main-react/",
  // "//localhost:8000/": "//wujie-micro.github.io/demo-main-vue/",
  "//localhost:8080/": isTest() ? "//192.168.133.99/" :  "//chuhai.chuhai1234.com",
  "//localhost:1002/": isTest() ? "//192.168.132.95/" :  "//chatgpt.chuhai1234.com",
};

export default function hostMap(host) {
  return map[host];
  // if (process.env.NODE_ENV === "production") return map[host];
  // return host;
}
